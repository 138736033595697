export const PrimaryNav = {
  parts: ["Title"],
  baseStyle: {
    Title: {
      fontSize: "20px",
      fontWeight: "600",
      color: "brand.white",
      marginBottom: "0px",
    },
  },
};
